import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => (
    <Layout>
        <SEO title="Page Not Found" />
        <div className="jumbotron">
 
            <Container>
                <Row>
                    <Col><h1>Page not found</h1></Col>
                </Row>
                <Row>
                    <Col>
                        <p>The page you're looking for does not exist.</p>
                        <p>If you think there's a problem with the website, please <Link to='/contact-us'>report it here</Link>.</p>
                        <p><Link to='/'>Head back to Home page</Link></p>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
)

export default NotFoundPage
